// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-area {
  transition: margin-left 0.3s ease;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}


.section {
  margin-bottom: 40px;
}

.section h2 {
  margin-bottom: 20px;
  margin-left: 0vw;
  color: #144040;
  font-size: 24px;
  font-weight: bold;
}

@media (max-width: 980px) {
.section h2 {
  margin-left: 25vw;
}
}`, "",{"version":3,"sources":["webpack://./src/Dashboard/CSS/contentArea.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,aAAa;EACb,WAAW;EACX,sBAAsB;AACxB;;;AAGA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;AACA;EACE,iBAAiB;AACnB;AACA","sourcesContent":[".content-area {\n  transition: margin-left 0.3s ease;\n  padding: 20px;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n\n.section {\n  margin-bottom: 40px;\n}\n\n.section h2 {\n  margin-bottom: 20px;\n  margin-left: 0vw;\n  color: #144040;\n  font-size: 24px;\n  font-weight: bold;\n}\n\n@media (max-width: 980px) {\n.section h2 {\n  margin-left: 25vw;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
