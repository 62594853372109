// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-layout {
  display: flex;
  height: 100vh;
  margin-left: 250px;
}

.sidebar {
  width: 250px; /* Adjust based on desired sidebar width */
  transition: transform 0.3s ease;
}

.sidebar.closed {
  transform: translateX(-100%);
}

@media (max-width: 768px) {
  .main-layout {
    /* Remove the margin so content is not pushed to the right */
    margin-left: 0;
  }

  .sidebar {
    /* Typically, position fixed so it sits on top of content */
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh; /* full height */
    width: 250px;  /* same or smaller, up to you */
    z-index: 1000; /* above main content */
  }
}`, "",{"version":3,"sources":["webpack://./src/Dashboard/CSS/dashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,YAAY,EAAE,0CAA0C;EACxD,+BAA+B;AACjC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE;IACE,4DAA4D;IAC5D,cAAc;EAChB;;EAEA;IACE,2DAA2D;IAC3D,eAAe;IACf,MAAM;IACN,OAAO;IACP,aAAa,EAAE,gBAAgB;IAC/B,YAAY,GAAG,+BAA+B;IAC9C,aAAa,EAAE,uBAAuB;EACxC;AACF","sourcesContent":[".main-layout {\n  display: flex;\n  height: 100vh;\n  margin-left: 250px;\n}\n\n.sidebar {\n  width: 250px; /* Adjust based on desired sidebar width */\n  transition: transform 0.3s ease;\n}\n\n.sidebar.closed {\n  transform: translateX(-100%);\n}\n\n@media (max-width: 768px) {\n  .main-layout {\n    /* Remove the margin so content is not pushed to the right */\n    margin-left: 0;\n  }\n\n  .sidebar {\n    /* Typically, position fixed so it sits on top of content */\n    position: fixed;\n    top: 0;\n    left: 0;\n    height: 100vh; /* full height */\n    width: 250px;  /* same or smaller, up to you */\n    z-index: 1000; /* above main content */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
